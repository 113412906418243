<template>
  <section
    id="txangurro"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row
            align="center"
            justify="center"
          >
            <v-card
              v-for="(product, i) in productos"
              :key="i"
              class="mx-auto mt-3"
              width="580"
              elevation="24"
            >
              <v-carousel
                hide-delimiter-background
                delimiter-icon="mdi-minus"
                height="630"
              >
                <v-carousel-item
                  v-for="(imagen, n) in product.imagenes"
                  :key="n"
                  container
                  :src="imagen.src"
                  :lazy-src="imagen.src"
                />
              </v-carousel>
              <v-card-title>
                {{ product.nombre }}
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-3 d-none d-lg-block d-print-block"
                      icon
                      v-bind="attrs"
                      @click="product.dialog = true"
                      v-on="on"
                    >
                      <v-btn
                        color="orange"
                        fab
                        small
                      >
                        <v-icon
                          color="white"
                        >
                          mdi-fullscreen
                        </v-icon>
                      </v-btn>
                    </v-btn>
                  </template>
                  <span>Pantalla completa</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      @click="product.mostrar = !product.mostrar"
                      v-on="on"
                    >
                      <v-btn
                        color="primary"
                        fab
                        small
                      >
                        <v-icon
                          color="white"
                        >
                          {{ product.mostrar ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                        </v-icon>
                      </v-btn>
                    </v-btn>
                  </template>
                  <span>Descripción</span>
                </v-tooltip>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="product.mostrar">
                  <v-divider />
                  <v-card-text>
                    {{ product.des }}
                    <span v-html="product.disp" />
                  </v-card-text>
                </div>
              </v-expand-transition>
              <v-dialog
                :key="product.disp"
                v-model="product.dialog"
                fullscreen
                transition="fade-transition"
              >
                <v-card
                  color="grey darken-4"
                  width="1080"
                  dark
                >
                  <v-app-bar
                    color="primary"
                    fixed
                    flat
                  >
                    <h2>{{ product.nombre }}</h2>
                    <v-spacer />
                    <v-btn
                      class="mx-1 mx-md-3"
                      icon
                      small
                      @click="product.dialog = !product.dialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-app-bar>
                  <v-responsive min-height="100vh">
                    <v-container
                      class="pa-0 fill-height"
                      fluid
                    >
                      <v-carousel
                        hide-delimiters
                        height="950"
                      >
                        <v-carousel-item
                          v-for="(pic, j) in product.imagenes"
                          :key="j"
                        >
                          <v-img
                            contain
                            :aspect-ratio="16/9"
                            :src="pic.src"
                            :lazy-src="pic.src"
                            :alt="pic.alt"
                          />
                        </v-carousel-item>
                      </v-carousel>
                    </v-container>
                  </v-responsive>
                </v-card>
              </v-dialog>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import tx950 from '@/assets/tx_950.jpg'
  import tx700 from '@/assets/tx_700.jpg'
  import tx2 from '@/assets/tx2.jpg'
  export default {
    name: 'Txangurro',
    metaInfo: { title: 'Txangurro' },
    data: () => ({
      productos: [
        {
          nombre: 'Txangurro',
          dialog: false,
          mostrar: false,
          des: 'Este producto va dirigido especialmente a la hostelería, por dos motivos que creemos fundamentales. Por su gran variedad de aplicaciones y por su importante ahorro en mano de obra, lo que le hace muy interesante en los establecimientos que lo utilizan.',
          disp: '<br>Diponibilidad<br><ul><li>950g: Caja de 6 unidades</li><li>700g: Caja de 2 unidades</li></ul>Conservación: entre 2ºC y 8ºC<br>Caducidad: 6 meses (950g) y 5 meses (700g)',
          imagenes: [
            {
              src: tx950,
              alt: 'Tarro de txangurro de 950 gramos',
            },
            {
              src: tx700,
              alt: 'Tarrina de txangurro de 700 gramos',
            },
            {
              src: tx2,
              alt: 'Presentación exquisita de ambos productos disponibles de txangurro por cantdelimar',
            },
          ],
        },
      ],
    }),
  }
</script>
